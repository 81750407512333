import {
  Backdrop,
  Button,
  Checkbox,
  ClickAwayListener,
  Hidden,
  InputAdornment,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Popover,
  Popper,
  Tab,
  Tabs,
  TextField,
} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Paper from "@material-ui/core/Paper";
// material-ui
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import withWidth from "@material-ui/core/withWidth";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CloseIcon from "@material-ui/icons/Close";
import EmailIcon from "@material-ui/icons/Email";
import HelpIcon from "@material-ui/icons/Help";
import LanguageIcon from "@material-ui/icons/Language";
import MenuIcon from "@material-ui/icons/Menu";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/RoomTwoTone";
import { Autocomplete } from "@material-ui/lab";
// components
import Page from "components/Page";
import Program from "hooks/Program";
import PropTypes from "prop-types";
import React, { Component } from "react";
import scriptLoader from "react-async-script-loader";
import { Helmet } from "react-helmet";
import { Bar, Container, Section } from "react-simple-resizer";
import { getJsonFromUrl } from "../../helpers/url/url";
import logo from "./logo_main.png";
import { MAP_STYLE } from "./mapStyle";
import posIcon from "./pos.png";
import styles from "./styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    position: "relative",
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const textStyles = makeStyles({
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
});

function CustomizedInputBase({
  google,
  onSelect,
  onAcademySelect,
  programTypes,
  popperOpen,
  openPopper,
  pinIcons,
  closePopper,
  updateURLParams,
  academies,
  width,
  recenterMap,
  handleChangeProgramTypeSettings,
}) {
  const classes = useStyles();
  const textClasses = textStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [predictions, setPredictions] = React.useState([]);
  const [value, setValue] = React.useState([...programTypes]);
  const nearEl = React.useRef(null);
  const [input, setInput] = React.useState("");
  const [service, setService] = React.useState(
    new google.maps.places.AutocompleteService(null)
  );

  const closeSearch = () => {
    setAnchorEl(null);
  };

  const handleChange = (e) => {
    setInput(e.target.value);
    if (popperOpen) closePopper();
    if (!anchorEl) setAnchorEl(nearEl.current);
    service.getQueryPredictions(
      {
        input: e.target.value,
        componentRestrictions: { country: "us" },
        types: ["(cities)"],
      },
      (s) => {
        setPredictions(s);
      }
    );
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
      <Paper
        component="form"
        className={classes.root}
        style={{
          position: "relative",
        }}
        elevation={2}
      >
        <InputAdornment position="start">Find</InputAdornment>
        <Autocomplete
          multiple
          id="tags-standard"
          style={{ flex: 1 }}
          limitTags={2}
          options={programTypes.filter((pt) => pt.visible)}
          disableCloseOnSelect
          onChange={(e, v) => handleChangeProgramTypeSettings(v)}
          getOptionLabel={(option) => option.name}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                style={{ background: option.color, color: "white" }}
                key={option}
                label={option.name}
                onDelete={() => console.log("test")}
                {...getTagProps({ index })}
              />
            ))
          }
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8, color: option.color }}
                checked={selected}
              />
              {option.name}
            </React.Fragment>
          )}
          renderInput={(params) => {
            params.InputProps.classes = textClasses;
            return (
              <TextField
                {...params}
                variant="standard"
                placeholder={"Youth Ensembles, Festivals, Colleges..."}
              />
            );
          }}
        />
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          style={{ marginTop: 16 }}
          autoFocus
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "bottom",
          }}
        ></Popper>
        <InputBase
          fullWidth
          ref={nearEl}
          style={{ flex: 1 }}
          autoFocus
          className={classes.input}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          value={input}
          inputProps={{ tabIndex: "1" }}
          onChange={handleChange}
          startAdornment={
            <InputAdornment position="start">Near</InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => {
                  setInput("");
                  setPredictions([]);
                  setAnchorEl(null);
                  updateURLParams("lat", undefined);
                  updateURLParams("lng", undefined);
                  updateURLParams("zoom", undefined);
                  recenterMap();
                }}
              >
                <CloseIcon style={{ fontSize: "1.25rem" }} />
              </IconButton>
            </InputAdornment>
          }
          placeholder="Los Angeles, New York, Chicago..."
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              e.stopPropagation();
              if (predictions && predictions.length) {
                onSelect(predictions[0]);
                setTimeout(() => setAnchorEl(null), 200);
              }
            }

            if (e.keyCode == 9) {
              debugger;
            }
          }}
        />
        <IconButton
          type="submit"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            openPopper();
          }}
          style={{
            color: popperOpen ? "#607d8b" : undefined,
          }}
        >
          <HelpIcon />
        </IconButton>
        <Popper
          open={Boolean(anchorEl) && predictions.length}
          anchorEl={anchorEl}
          autoFocus
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "bottom",
          }}
        >
          <Paper
            style={{
              width:
                width === "sm" || width === "xs" ? "calc(100vw - 16px)" : 400,
            }}
            elevation={10}
          >
            <List dense style={{ maxHeight: 400, overflow: "scroll" }}>
              {predictions &&
                predictions.map((p, i) => (
                  <ListItem
                    tabIndex={Number(i) + 1}
                    button
                    onClick={() => {
                      onSelect(p);
                      setInput(p.description);
                      setTimeout(() => setAnchorEl(null), 200);
                    }}
                  >
                    <ListItemAvatar>
                      <RoomIcon style={{ fontSize: 30 }} />
                    </ListItemAvatar>
                    <ListItemText key={p.place_id} primary={p.description} />
                  </ListItem>
                ))}
            </List>
          </Paper>
        </Popper>
      </Paper>
    </ClickAwayListener>
  );
}

class App extends Component {
  static propTypes = {
    classes: PropTypes.object,
    getAcademyPrograms: PropTypes.func,
    getProgramById: PropTypes.func,
    programTypes: PropTypes.array,
    academies: PropTypes.array,
    width: PropTypes.any,
    levels: PropTypes.array,
    ages: PropTypes.array,
    divisions: PropTypes.array,
    grades: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { academies, programTypes } = this.props;
    this.map = React.createRef();
    const urlParams = getJsonFromUrl(window.location);
    let selectedAcademy, programs;
    let programTypeSettings = {};
    if (programTypes) {
      for (const key in programTypes) {
        if (programTypes.hasOwnProperty(key)) {
          const programType = programTypes[key];
          programTypeSettings[programType.id] = true;
        }
      }
    }

    if (urlParams.academyID && academies && academies.length) {
      selectedAcademy = academies.find(
        (a) => a.id === Number(urlParams.academyID)
      );
    }

    this.state = {
      map: undefined,
      academyList: true,
      infoWindow: undefined,
      geocoder: undefined,
      markersLoaded: false,
      legend: true,
      tabIndex: 0,
      selectedAcademy,
      programTypeSettings,
    };

    this.searchInput = React.createRef();
    this.genPinIcons();

    this.containerRef = React.createRef();
    if (!urlParams.academyID) {
      setTimeout(() => {
        this.setState({ popperAnchor: this.searchInput.current });
      }, 1000);
    }
  }

  componentWillReceiveProps(nextProps) {
    const { isScriptLoaded, isScriptLoadSucceed, academies, filterKey } =
      nextProps;

    if (filterKey !== this.props.filterKey) {
      this.showMarkers(academies);
    }

    if (isScriptLoaded && !this.props.isScriptLoaded) {
      // load finished
      if (isScriptLoadSucceed) {
        this.google = window.google;
        this.state.infoWindow = new window.google.maps.InfoWindow();
        this.state.geocoder = new window.google.maps.Geocoder();
        this.initMap();
      }
    }
  }

  getLatLgn(address, callback) {
    const { zipcode, city, state } = this.state;

    this.geocoder.geocode({ address }, (results, status) => {
      if (status === "OK" && results.length) {
        const result = results[0];
        callback({
          lat: result.geometry.location.lat(),
          lng: result.geometry.location.lng(),
        });
      }
    });
  }

  getSnippet(academy) {
    const { programTypes } = this.props;
    let p = "";
    for (const programTypeID in academy.programs) {
      if (academy.programs.hasOwnProperty(programTypeID)) {
        const programType = programTypes.find(
          (pt) => pt.id === Number(programTypeID)
        );
        console.log(programTypeID, programType);
        if (programType && programType.id !== 9) {
          p += `<div>${academy.programs[programTypeID]} ${programType.name}</div>`; // Scholarship TO REMOVE LATER
        }
      }
    }
    return `<div><div style="font-size: 14px; font-weight: 700;">${academy.name}</div><hr /><div>${p}</div></div>`;
  }

  getAcademyContent(academy) {
    const { programTypes } = this.props;
    let p = [];
    for (const programTypeID in academy.programs) {
      if (academy.programs.hasOwnProperty(programTypeID)) {
        const programType = programTypes.find(
          (pt) => pt.id === Number(programTypeID)
        );
        if (programType.visible) {
          p.push(`${academy.programs[programTypeID]} ${programType.name}`); // Scholarship TO REMOVE LATER
        }
      }
    }
    return p.join(",");
  }

  initMap() {
    const { academies } = this.props;
    const { infoWindow, map, selectedAcademy } = this.state;
    const urlParams = getJsonFromUrl(window.location);

    let _map;

    if (map === undefined) {
      const urlParams = getJsonFromUrl(window.location);
      _map = new this.google.maps.Map(this.map.current, {
        center: {
          lat: Number(urlParams.lat) || 39.5,
          lng: Number(urlParams.lng) || -98.35,
        },
        zoom: Number(urlParams.zoom) || 4,
        streetViewControl: false,
        mapTypeControlOptions: {
          mapTypeIds: [],
        },
        styles: MAP_STYLE,
      });
      this.state.map = _map;
    } else {
      _map = map;
    }

    this.showMarkers(academies, () => {
      if (urlParams.academyID && selectedAcademy) {
        this.showAcademy(selectedAcademy);
      }
    });

    const _this = this;

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          var pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          infoWindow.setPosition(pos);
          _map.setCenter(pos);
          _map.setZoom(8);
          const marker = new _this.google.maps.Marker({
            clickable: false,
            position: pos,
            icon: posIcon,
            shadow: null,
            zIndex: 999,
            map: _map,
          });
        },
        function () {
          // this.handleLocationError(true, infoWindow, _map.getCenter());
        }
      );
    } else {
      // Browser doesn't support Geolocation
      // this.handleLocationError(false, infoWindow, _map.getCenter());
    }
  }

  handleLocationError(browserHasGeolocation, infoWindow, pos) {
    this.infoWindow.setPosition(pos);
    this.infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation."
    );
    this.infoWindow.open(this.map);
  }

  setMapOnAll(map) {
    const { markers } = this.state;
    if (markers) {
      for (var i = 0; i < markers.length; i++) {
        markers[i].setMap(map);
      }
    }
  }

  genPinIcons() {
    let tmp = 0b00000;
    const pinIcons = {};
    while (tmp <= 0b11111) {
      pinIcons[tmp] = this.getSVGPin(tmp);
      tmp += 0b00001;
    }

    this.state.pinIcons = pinIcons;
  }

  getSVGPin(value) {
    const { programTypes } = this.props;
    const spot1 = value & 0b10000 ? programTypes[0].color : "#9e9e9e";
    const spot2 = value & 0b01000 ? programTypes[1].color : "#9e9e9e";
    const spot3 = value & 0b00100 ? programTypes[2].color : "#9e9e9e";
    // const spot4 = value & 0b00010 ? programTypes[3].color : "#9e9e9e";
    const spot4 = "#9e9e9e";
    const spot5 = value & 0b00001 ? programTypes[4].color : "#9e9e9e";

    var svgBlob = new Blob(
      [
        `<svg id="svg" width="32px" height="40px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.85 40">`,
        `<path
            fill="#fff"
            d="M15.93,0A15.93,15.93,0,0,0,0,15.93C0,29.16,15.93,40,15.93,40S31.85,28.37,31.85,15.92A15.92,15.92,0,0,0,15.93,0Z"
          />`,
        `<path
            fill="${spot1}"
            d="M27.85,13.46A1.48,1.48,0,0,1,25,13.27a9.42,9.42,0,0,0-3.24-4.64,9.53,9.53,0,0,0-4.13-1.84l.15-.17a2.33,2.33,0,0,0,.48-1.74,2.31,2.31,0,0,0-.39-1,12.48,12.48,0,0,1,10,8.54c0,.1.06.19.08.28a.15.15,0,0,1,0,.07A1.38,1.38,0,0,1,27.85,13.46Z"
          />`,
        `<path
            fill="${spot2}"
            d="M25.81,23.84a12.69,12.69,0,0,1-2.4,2.35,1.48,1.48,0,1,1-1.67-2.37l0,0A10,10,0,0,0,23.48,22a9.52,9.52,0,0,0,1.93-6.92,2.31,2.31,0,0,0,1,.25l.3,0a2.39,2.39,0,0,0,1.55-.87A12.51,12.51,0,0,1,25.81,23.84Z"
          />`,
        `<path
            fill="${spot3}"
            d="M11.85,27.89a12.44,12.44,0,0,1-3-1.52A1.48,1.48,0,1,1,10.56,24l0,0a10,10,0,0,0,2.22,1.1A9.51,9.51,0,0,0,20,24.71a2.29,2.29,0,0,0,.1,1.07c0,.09.07.18.11.28a2.36,2.36,0,0,0,1.32,1.18A12.43,12.43,0,0,1,11.85,27.89Z"
          />`,
        `<path
            fill="${spot4}"
            d="M9.1,22.86a2.35,2.35,0,0,0-1.91,2.26A12.53,12.53,0,0,1,4,12.56H4a1.5,1.5,0,0,1,.6-.85,1.48,1.48,0,0,1,2.06.39,1.46,1.46,0,0,1,.1,1.48A9.58,9.58,0,0,0,9.1,22.86Z"
          />`,
        `<path
            fill="${spot5}"
            d="M17.1,6.08a1.49,1.49,0,0,1-1.31.56,9.52,9.52,0,0,0-7.41,3.7,9.29,9.29,0,0,0-.93,1.44,2.12,2.12,0,0,0-.59-.68,2.34,2.34,0,0,0-1.75-.48l-.42.09a12.5,12.5,0,0,1,11.11-7,1.48,1.48,0,0,1,1.3,2.38Z"
          />`,
        `</svg>`,
      ],
      { type: "image/svg+xml" }
    );
    var svgUrl = URL.createObjectURL(svgBlob);
    return svgUrl;
  }

  showMarkers(academies, callback) {
    const { map } = this.state;
    const markers = [];
    this.setMapOnAll(null);
    for (const key in academies) {
      if (academies.hasOwnProperty(key)) {
        const academy = academies[key];
        if (academy.latitude && academy.latitude.length) {
          let pinV = 0b00000;
          if (academy.programs) {
            if (academy.programs[2]) pinV += 0b10000;
            if (academy.programs[3]) pinV += 0b01000;
            if (academy.programs[4]) pinV += 0b00100;
            // if (academy.programsByProgramTypes[9]) pinV += 0b00010; // Scholarship TO REMOVE LATER
            if (academy.programs[10]) pinV += 0b00001;
          }

          if (pinV !== 0b00000) {
            const marker = new this.google.maps.Marker({
              position: {
                lat: Number(academy.latitude),
                lng: Number(academy.longitude),
              },
              map: map,
              icon: {
                url: this.state.pinIcons[pinV],
                fillColor: "#FF0000",
              },
              metadata: academy,
            });
            const infowindow = new this.google.maps.InfoWindow({
              content: this.getSnippet(academy),
            });

            if (!academy.hidden) {
              marker.addListener("mouseover", () => {
                if (marker.opacity !== 0) infowindow.open(map, marker);
              });
              marker.addListener("mouseout", () => {
                infowindow.close(map, marker);
              });
              marker.addListener("click", () => {
                if (marker.opacity !== 0) this.showAcademy(academy);
              });
            }
            markers.push(marker);
          }
        }
      }
    }
    this.setState(
      {
        markers,
        markersLoaded: true,
      },
      callback
    );
  }

  showProgramMarkers(academy, programs) {
    const { map } = this.state;
    const programMarkers = [];
    const polylines = [];
    const latlng = [
      new this.google.maps.LatLng(
        parseFloat(academy.latitude),
        parseFloat(academy.longitude)
      ),
    ];
    for (const key in programs) {
      if (Object.hasOwnProperty.call(programs, key)) {
        const program = programs[key];
        if (
          program.latitude &&
          `${program.latitude},${program.longitude}` !=
            `${academy.latitude},${academy.longitude}`
        ) {
          const marker = new this.google.maps.Marker({
            position: {
              lat: Number(program.latitude),
              lng: Number(program.longitude),
            },
            map: map,
            metadata: program,
          });

          const polyline = new this.google.maps.Polyline({
            path: [
              {
                lat: parseFloat(academy.latitude),
                lng: parseFloat(academy.longitude),
              },
              {
                lat: parseFloat(program.latitude),
                lng: parseFloat(program.longitude),
              },
            ],
            geodesic: true,
            strokeColor: "#ff9800",
            strokeOpacity: 1.0,
            strokeWeight: 2,
          });

          polyline.setMap(map);
          marker.setMap(map);

          polylines.push(polyline);
          programMarkers.push(marker);

          latlng.push(
            new this.google.maps.LatLng(program.latitude, program.longitude)
          );
        }
      }
    }

    if (latlng.length > 1) {
      var latlngbounds = new this.google.maps.LatLngBounds();
      for (var i = 0; i < latlng.length; i++) {
        latlngbounds.extend(latlng[i]);
      }
      map.fitBounds(latlngbounds);
    }

    this.setState({
      polylines,
      programMarkers,
    });
  }

  hidePolylines() {
    const { polylines } = this.state;
    if (polylines) {
      for (const key in polylines) {
        if (Object.hasOwnProperty.call(polylines, key)) {
          const polyline = polylines[key];
          polyline.setMap(null);
        }
      }
    }
  }

  hideProgramMarkers() {
    const { programMarkers } = this.state;
    if (programMarkers) {
      for (const key in programMarkers) {
        if (Object.hasOwnProperty.call(programMarkers, key)) {
          const programMarker = programMarkers[key];
          programMarker.setMap(null);
        }
      }
    }
  }

  showAcademy(academy) {
    const { getAcademyPrograms, getAcademyByID } = this.props;
    const { map } = this.state;
    this.setState({ tabIndex: 0 });
    getAcademyByID(academy.id).then((r) => {
      this.setState({ selectedAcademy: r.payload });
    });
    getAcademyPrograms(academy.id).then((r) => {
      this.setState({ programs: r.payload });
      this.showProgramMarkers(academy, r.payload);
    });

    this.updateURLParams("academyID", academy.id);
    this.hidePolylines();
    this.hideProgramMarkers();
    map.setZoom(10);
    this.showOnMap(academy);
  }

  updateURLParams(name, value) {
    var params = new URLSearchParams(window.location.search);
    if (value) {
      params.set(name, value);
    } else {
      params.delete(name);
    }
    var newUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      params.toString();
    window.history.pushState({ path: newUrl }, "", newUrl);
  }

  hideMarkers(markers) {
    for (const key in markers) {
      if (markers.hasOwnProperty(key)) {
        const marker = markers[key];
        marker.setMap(null);
      }
    }
  }

  geocode(p) {
    this.state.geocoder.geocode({ address: p.description }, (results) => {
      const selected = results[0];
      var pos = {
        lat: selected.geometry.location.lat(),
        lng: selected.geometry.location.lng(),
      };
      this.updateURLParams("lat", selected.geometry.location.lat());
      this.updateURLParams("lng", selected.geometry.location.lng());
      this.updateURLParams("zoom", 8);
      this.state.map.setCenter(pos);
      this.state.map.setZoom(8);
      setTimeout(this.forceUpdate.bind(this), 1000);
      // this.setState({
      //   selected,
      // });
      // this.openLocationsByAddress();
    });
  }

  handleChangeProgramTypeSettings(selectedProgramTypes) {
    const { academies, programTypes } = this.props;
    const _programTypeSettings = {};
    if (selectedProgramTypes.length === 0) {
      this.hideMarkers();
      this.showMarkers(academies);
      this.setState({ filteredAcademies: academies });
      return;
    }

    for (const key in selectedProgramTypes) {
      if (Object.hasOwnProperty.call(selectedProgramTypes, key)) {
        const selectedProgramType = selectedProgramTypes[key];
        _programTypeSettings[selectedProgramType.id] = true;
      }
    }

    this.setState({ programTypeSettings: _programTypeSettings });
    const filteredAcademies = academies.filter((a) => {
      if (a.programs) {
        for (const key in a.programs) {
          if (a.programs.hasOwnProperty(key)) {
            if (_programTypeSettings[key]) return true;
          }
        }
      }
      return false;
    });
    this.hideMarkers();
    this.showMarkers(filteredAcademies);
    this.setState({ filteredAcademies });
  }

  showOnMap(a) {
    const { markers, map } = this.state;

    for (const key in markers) {
      if (Object.hasOwnProperty.call(markers, key)) {
        const marker = markers[key];
        if (marker.metadata.id !== a.id) {
          marker.setOptions({ opacity: 0 });
        } else {
          marker.setOptions({ opacity: 1 });
          const latLng = marker.getPosition();
          map.setCenter(latLng);
        }
      }
    }
  }

  getAcademyMarker(academy) {
    const { markers } = this.state;
    for (const key in markers) {
      if (Object.hasOwnProperty.call(markers, key)) {
        const marker = markers[key];
        if (marker.metadata.id === academy.id) return marker;
      }
    }
    return undefined;
  }

  showAll() {
    const { markers, map } = this.state;
    for (const key in markers) {
      if (Object.hasOwnProperty.call(markers, key)) {
        const marker = markers[key];
        marker.setOptions({ opacity: 1 });
      }
    }
    this.recenterMap();
  }

  recenterMap(lat, lng, zoom) {
    const { map } = this.state;
    const urlParams = getJsonFromUrl(window.location);
    if (urlParams.lat && urlParams.lng && urlParams.zoom) {
      map.setCenter({ lat: Number(urlParams.lat), lng: Number(urlParams.lng) });
      map.setZoom(urlParams.zoom);
    } else if (lat && lng) {
      map.setCenter({ lat, lng });
      map.setZoom(zoom || 4);
    } else {
      map.setCenter({ lat: 39.5, lng: -98.35 });
      map.setZoom(4);
    }
    this.forceUpdate();
  }

  filterWithRadius(data, radius) {
    var urlParams = getJsonFromUrl(window.location);
    if (urlParams.lat && urlParams.lng && this.google && this.google.maps) {
      const _data = [];
      const lat = parseFloat(urlParams.lat);
      const lng = parseFloat(urlParams.lng);
      for (const key in data) {
        if (Object.hasOwnProperty.call(data, key)) {
          const datum = data[key];
          if (datum.latitude && datum.longitude) {
            const _lat = parseFloat(datum.latitude);
            const _lng = parseFloat(datum.longitude);
            if (
              this.google.maps.geometry.spherical.computeDistanceBetween(
                new this.google.maps.LatLng(lat, lng),
                new this.google.maps.LatLng(_lat, _lng)
              ) /
                1000 <=
              radius
            )
              _data.push(datum);
          }
        }
      }
      return _data;
    }
    return data;
  }

  getAcademyProgramTypes() {
    const { programs } = this.state;
    const { programTypes, classes } = this.props;
    const pr = [];
    for (const key in programs) {
      if (Object.hasOwnProperty.call(programs, key)) {
        const program = programs[key];
        const programType = programTypes.find(
          (pt) => pt.id === program.programTypeID
        );
        if (programType.visible && pr.indexOf(programType) === -1) {
          pr.push(programType);
        }
      }
    }

    return pr;
  }

  getTabs() {
    const { classes } = this.props;
    const { tabIndex } = this.state;
    const pr = this.getAcademyProgramTypes();

    return (
      <Tabs
        value={tabIndex}
        tabItemContainerStyle={{ width: "100px" }}
        variant="scrollable"
        scrollButtons="auto"
        size="small"
        style={{ minHeight: "auto" }}
        onChange={(e, v) => {
          this.setState({ tabIndex: v });
        }}
        aria-label="simple tabs example"
      >
        {pr.map((p) => (
          <Tab
            classes={{
              root: classes.tabsRoot,
              wrapper: classes.labelContainer,
            }}
            style={{
              background: p.color,
            }}
            size="small"
            label={p.name}
          />
        ))}
      </Tabs>
    );
  }

  render() {
    const {
      classes,
      programTypes,
      getProgramById,
      academies,
      ages,
      grades,
      width,
      levels,
      isProgramTypeVisible,
    } = this.props;
    const {
      selectedAcademy,
      loading,
      popperAnchor,
      filteredAcademies,
      programs,
      tabIndex,
    } = this.state;

    let programTypeDesc = [];
    let pt = programTypes.filter((p) => p.visible);
    for (const key in pt) {
      if (pt.hasOwnProperty(key)) {
        const programType = pt[key];
        if (!programType.visible) break;
        if (Number(key) === pt.length - 1) {
          programTypeDesc.push(<span>and then </span>);
        }
        programTypeDesc.push(
          <span
            style={{ color: programType.color || "#9e9e9e", fontWeight: 600 }}
          >
            {programType.name}
          </span>
        );
        if (Number(key) !== pt.length - 1) {
          programTypeDesc.push(<span>, </span>);
        }
      }
    }
    let data = filteredAcademies || academies;
    data = this.filterWithRadius(data, 100);
    let sortedAcademies = data.sort((a, b) => {
      if (
        a.name.replace(/\s/g, "").toLowerCase() <
        b.name.replace(/\s/g, "").toLowerCase()
      ) {
        return -1;
      }
      if (
        a.name.replace(/\s/g, "").toLowerCase() >
        b.name.replace(/\s/g, "").toLowerCase()
      ) {
        return 1;
      }
      return 0;
    });

    sortedAcademies = sortedAcademies.filter(
      (a) => Object.keys(a.programs).length > 0
    );

    console.log(selectedAcademy);

    return (
      <Page noPadding helmet="College Prep For Musicians: Map">
        <div className={classes.root}>
          <div style={{ height: 100 }} className={classes.borderBottom}>
            <Grid container style={{ padding: 20 }} justify="space-between">
              <Hidden smDown>
                <Grid item>
                  <img
                    src={logo}
                    height="70"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        "https://www.collegeprepformusicians.com/",
                        "_blank"
                      )
                    }
                  />
                </Grid>
              </Hidden>
              <Grid
                item
                xs={12}
                md={6}
                ref={this.searchInput}
                style={{ position: "relative" }}
              >
                {this.google ? (
                  <div
                    style={{
                      zIndex: 9999,
                      position: "absolute",
                      width: "calc(100% - 8px)",
                    }}
                  >
                    <CustomizedInputBase
                      width={width}
                      programTypes={programTypes}
                      handleChangeProgramTypeSettings={this.handleChangeProgramTypeSettings.bind(
                        this
                      )}
                      updateURLParams={this.updateURLParams.bind(this)}
                      recenterMap={this.recenterMap.bind(this)}
                      pinIcons={this.state.pinIcons}
                      academies={academies}
                      google={this.google}
                      popperOpen={Boolean(popperAnchor)}
                      openPopper={() =>
                        this.setState({
                          popperAnchor: this.searchInput.current,
                        })
                      }
                      closePopper={() => this.setState({ popperAnchor: null })}
                      onSelect={this.geocode.bind(this)}
                      onAcademySelect={this.showAcademy.bind(this)}
                    />
                  </div>
                ) : (
                  []
                )}
                <Popover
                  open={Boolean(popperAnchor)}
                  disableAutoFocus
                  anchorEl={popperAnchor}
                  disableEnforceFocus
                  onClose={() => this.setState({ popperAnchor: null })}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  style={{
                    marginTop:
                      width === "sm" || width === "xs" ? 70 : undefined,
                  }}
                >
                  <div style={{ maxWidth: 600, padding: 20, display: "flex" }}>
                    <div
                      style={{
                        background: "#cfd8dc",
                        padding: 10,
                        width: 110,
                        position: "relative",
                        margin: "auto",
                        borderRadius: 8,
                      }}
                    >
                      <img
                        src={this.state.pinIcons[0b11111]}
                        style={{ width: "100%" }}
                      />
                    </div>
                    <Typography style={{ marginLeft: 16 }}>
                      Check out our new Map, a database to access{" "}
                      {programTypeDesc}.
                      <br />
                      Use this search bar to filter options you are looking for,
                      or enter a location to search for surrounding musical
                      opportunities!
                    </Typography>
                  </div>
                  <div
                    style={{
                      margin: "0px 20px 20px 20px",
                    }}
                  >
                    <Button
                      fullWidth
                      onClick={() => this.setState({ popperAnchor: null })}
                      style={{ background: "#607d8b", color: "white" }}
                    >
                      Got it
                    </Button>
                  </div>
                </Popover>
                <Backdrop
                  open={Boolean(popperAnchor)}
                  style={{ zIndex: 999 }}
                />
              </Grid>
              <Grid item />
            </Grid>
          </div>
          <div style={{ flexGrow: 1, display: "flex" }}>
            <Container
              ref={this.containerRef}
              style={{ flex: 1 }}
              onActivate={() => console.log("onActivate")}
            >
              {sortedAcademies ? (
                <Section
                  defaultSize={300}
                  minSize={300}
                  maxSize={300}
                  style={{
                    overflowY: "scroll",
                    overflowX: "hidden",
                    maxHeight: "calc(calc(var(--vh, 1vh) * 100) - 101px)",
                  }}
                >
                  <List dense size="small">
                    {sortedAcademies.map((a, i) => {
                      const JSX = [];
                      if (Number(i) === 0) {
                        JSX.push(
                          <ListSubheader
                            style={{ background: "rgb(220,220,220)" }}
                          >
                            {a.name.replace(/\s/g, "").toUpperCase()[0]}
                          </ListSubheader>
                        );
                      }
                      if (
                        Number(i) > 0 &&
                        sortedAcademies[Number(i) - 1].name
                          .replace(/\s/g, "")
                          .toUpperCase()[0] !==
                          a.name.replace(/\s/g, "").toUpperCase()[0]
                      ) {
                        JSX.push(
                          <ListSubheader
                            style={{ background: "rgb(220,220,220)" }}
                          >
                            {a.name.replace(/\s/g, "").toUpperCase()[0]}
                          </ListSubheader>
                        );
                      }
                      let pinV = 0b00000;
                      if (a.programs) {
                        if (a.programs[2]) pinV += 0b10000;
                        if (a.programs[3]) pinV += 0b01000;
                        if (a.programs[4]) pinV += 0b00100;
                        if (a.programs[9]) pinV += 0b00010;
                        if (a.programs[10]) pinV += 0b00001;
                      }
                      if (this.getAcademyContent(a))
                        JSX.push(
                          <ListItem
                            dense
                            button
                            selected={
                              selectedAcademy && selectedAcademy.id === a.id
                            }
                            onClick={() => this.showAcademy(a)}
                            onMouseEnter={() => {
                              if (!this.state.selectedAcademy)
                                this.showOnMap(a);
                            }}
                            onMouseLeave={() => {
                              if (!this.state.selectedAcademy) this.showAll();
                            }}
                          >
                            <ListItemAvatar>
                              <img
                                src={this.state.pinIcons[pinV]}
                                height={40}
                              />
                            </ListItemAvatar>
                            <div style={{ width: 170 }}>
                              <Typography>{a.name}</Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                              >
                                {this.getAcademyContent(a)}
                              </Typography>
                            </div>
                            {a.picture ? (
                              <ListItemSecondaryAction
                                style={{ padding: 0, width: 40 }}
                              >
                                <img
                                  src={a.picture}
                                  alt={"image"}
                                  style={{ height: 40, width: 40 }}
                                />
                              </ListItemSecondaryAction>
                            ) : (
                              []
                            )}
                          </ListItem>
                        );
                      return JSX;
                    })}
                  </List>
                </Section>
              ) : (
                []
              )}
              <Section
                key={selectedAcademy}
                defaultSize={selectedAcademy ? 400 : 0}
                style={{
                  overflowY: "scroll",
                  overflowX: "hidden",
                  width: selectedAcademy ? "30%" : 0,
                  maxHeight: "calc(calc(var(--vh, 1vh) * 100) - 101px)",
                }}
              >
                {selectedAcademy && Object.keys(selectedAcademy).length > 6 ? (
                  <div style={{ position: "relative" }}>
                    {selectedAcademy && selectedAcademy.id ? (
                      <Grid
                        container
                        spacing={3}
                        style={{ padding: 16 }}
                        justify="space-between"
                      >
                        <Helmet>
                          <title>{selectedAcademy.name}</title>
                          <meta
                            property="og:title"
                            content={selectedAcademy.name}
                          />
                          <meta
                            property="og:description"
                            content={`${selectedAcademy.address}, ${selectedAcademy.city}, ${selectedAcademy.state} ${selectedAcademy.zipcode}`}
                          />
                          <meta
                            property="og:image"
                            content={selectedAcademy.picture}
                          />
                          <meta
                            property="og:url"
                            content={`https://map.collegeprepformusicians.com/?academyID=${selectedAcademy.id}`}
                          ></meta>
                          {selectedAcademy.picture
                            ? [
                                <meta
                                  property="og:image"
                                  content={selectedAcademy.picture}
                                />,
                                <meta
                                  name="twitter:image"
                                  content={selectedAcademy.picture}
                                />,
                              ]
                            : []}
                        </Helmet>
                        <Grid item xs={12}>
                          <div style={{ display: "flex" }}>
                            {selectedAcademy.picture ? (
                              <img
                                src={selectedAcademy.picture}
                                style={{
                                  marginRight: 20,
                                  height: 100,
                                  width: 100,
                                }}
                              />
                            ) : (
                              []
                            )}
                            <div>
                              <Typography
                                style={{
                                  fontSize: 24,
                                  fontWeight: 700,
                                  flex: 1,
                                }}
                              >
                                {selectedAcademy.name}
                              </Typography>
                              <Typography
                                variant="caption"
                                color="textSecondary"
                                style={{ fontWeight: 700 }}
                              >
                                {`${selectedAcademy.address}, ${selectedAcademy.city}, ${selectedAcademy.state} ${selectedAcademy.zipcode}`}
                              </Typography>
                            </div>
                          </div>
                          <br />
                          <Grid container spacing={1}>
                            {selectedAcademy.website &&
                            selectedAcademy.website.length ? (
                              <Grid item>
                                <Tooltip title={selectedAcademy.website}>
                                  <Chip
                                    label="Website"
                                    size="small"
                                    onClick={() =>
                                      window.open(selectedAcademy.website)
                                    }
                                    icon={<LanguageIcon />}
                                  />
                                </Tooltip>
                              </Grid>
                            ) : (
                              []
                            )}
                            {selectedAcademy.phone &&
                            selectedAcademy.phone.length ? (
                              <Grid item>
                                <Tooltip title={selectedAcademy.phone}>
                                  <Chip
                                    label="Phone"
                                    size="small"
                                    onClick={() =>
                                      window.open(
                                        `tel:${selectedAcademy.phone}`
                                      )
                                    }
                                    icon={<PhoneIcon />}
                                  />
                                </Tooltip>
                              </Grid>
                            ) : (
                              []
                            )}
                            {selectedAcademy.email &&
                            selectedAcademy.email.length ? (
                              <Grid item>
                                <Tooltip title={selectedAcademy.email}>
                                  <Chip
                                    label="Email"
                                    size="small"
                                    onClick={() =>
                                      window.open(
                                        `mailTo:${selectedAcademy.email}`
                                      )
                                    }
                                    icon={<EmailIcon />}
                                  />
                                </Tooltip>
                              </Grid>
                            ) : (
                              []
                            )}
                          </Grid>
                        </Grid>
                        <IconButton
                          size="small"
                          style={{
                            background: "rgba(155,155,155,0.2)",
                            position: "absolute",
                            top: 16,
                            right: 4,
                          }}
                          onClick={() => {
                            this.setState({ selectedAcademy: undefined });
                            var params = new URLSearchParams(
                              window.location.search
                            );
                            this.hidePolylines();
                            this.hideProgramMarkers();
                            this.showAll();
                            params.delete("academyID");
                            var newUrl =
                              window.location.protocol +
                              "//" +
                              window.location.host +
                              window.location.pathname +
                              "?" +
                              params.toString();
                            window.history.pushState(
                              { path: newUrl },
                              "",
                              newUrl
                            );
                          }}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                        {loading ? (
                          <Grid item>
                            <CircularProgress />
                          </Grid>
                        ) : (
                          []
                        )}
                        {this.getTabs(selectedAcademy)}
                        {programs &&
                          programs
                            .filter(
                              (p) =>
                                isProgramTypeVisible[p.programTypeID] &&
                                p.programTypeID ===
                                  this.getAcademyProgramTypes()[tabIndex].id
                            )
                            .map((p) => (
                              <Grid item xs={12}>
                                <Program
                                  p={p}
                                  programTypes={programTypes}
                                  key={p.id}
                                  getProgramById={getProgramById}
                                  ages={ages}
                                  grades={grades}
                                  levels={levels}
                                />
                              </Grid>
                            ))}
                      </Grid>
                    ) : (
                      []
                    )}
                  </div>
                ) : (
                  []
                )}
              </Section>
              <Bar size={4} style={{ cursor: "col-resize" }} />
              <Section
                minSize={100}
                style={{ overflow: "scroll", position: "relative" }}
              >
                <div
                  ref={this.map}
                  className={classes.flex}
                  style={{ height: "100%" }}
                ></div>
                <div>
                  <IconButton
                    onClick={() => {
                      const { academyList } = this.state;
                      this.setState({
                        academyList: !academyList,
                      });
                      this.updateURLParams(academyList, !academyList);
                    }}
                    style={{ position: "absolute", top: 10, left: 10 }}
                  >
                    <MenuIcon style={{ color: "white" }} />
                  </IconButton>
                </div>
                <Chip
                  color="primary"
                  onClick={() =>
                    window.open("mailto:info@collegeprepformusicians.com")
                  }
                  size="small"
                  icon={<EmailIcon />}
                  label="Contact Us"
                  style={{ position: "absolute", bottom: 24, right: 62 }}
                />
              </Section>
            </Container>
          </div>
        </div>
      </Page>
    );
  }
}
export default scriptLoader([
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyCosTxjA46yI2BUjLmmiUK3jJc2PK8IVIk&libraries=places,geometry",
])(withWidth()(withStyles(styles)(App)));
