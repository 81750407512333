import { networkAction } from 'helpers/network/networkAction';

import {
  GET_MUSICAL_FAMILIES,
  GET_MUSICAL_FAMILY_BY_ID,
  DELETE_MUSICAL_FAMILY,
  UPDATE_MUSICAL_FAMILY,
  CREATE_MUSICAL_FAMILY,
} from 'constants/musicalFamilies';

import * as Api from 'api';

export const getMusicalFamilies = () => async dispatch => networkAction(
  dispatch,
  GET_MUSICAL_FAMILIES,
  Api.getMusicalFamilies,
  [],
);

export const getMusicalFamilyById = id => async dispatch => networkAction(
  dispatch,
  GET_MUSICAL_FAMILY_BY_ID,
  Api.getMusicalFamilyById,
  [id],
);

export const deleteMusicalFamily = id => async dispatch => networkAction(
  dispatch,
  DELETE_MUSICAL_FAMILY,
  Api.deleteMusicalFamily,
  [id],
);

export const createMusicalFamily = body => async dispatch => networkAction(
  dispatch,
  CREATE_MUSICAL_FAMILY,
  Api.createMusicalFamily,
  [body],
);

export const updateMusicalFamily = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_MUSICAL_FAMILY,
  Api.updateMusicalFamily,
  [id, body],
);
