export default theme => ({
  container: {
    padding: 5,
  },
  tag: {
    color: 'white',
    background: '#0076FF',
    padding: '4px 6px',
    borderRadius: 20,
    fontSize: 12,
  }
});
