import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'cpfm';
const programMusicalFamilies = `/${service}/programMusicalFamilies`;

export function getProgramMusicalFamilies() {
  const url = `${programMusicalFamilies}`;
  return {
    method: GET,
    url,
  };
}

export function getProgramMusicalFamilyById(id) {
  const url = `${programMusicalFamilies}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateProgramMusicalFamily(id, body) {
  const url = `${programMusicalFamilies}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProgramMusicalFamily(id) {
  const url = `${programMusicalFamilies}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProgramMusicalFamily(body) {
  const url = `${programMusicalFamilies}`;
  return {
    method: POST,
    url,
    body,
  };
}
