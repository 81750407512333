import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AssistantPhotoIcon from "@material-ui/icons/AssistantPhoto";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import EventIcon from "@material-ui/icons/Event";
import PersonIcon from "@material-ui/icons/Person";
import RoomIcon from "@material-ui/icons/Room";
import SchoolIcon from "@material-ui/icons/School";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Program({
  p,
  getProgramById,
  programTypes,
  grades,
  ages,
  levels,
}) {
  const [hover, setHover] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [program, setProgram] = React.useState(p);
  const [programReq, setProgramReq] = React.useState(() => {
    getProgramById(p.id).then((r) => {
      if (r.success) {
        setProgram(r.payload);
        setLoading(false);
      }
    });
  });
  const classes = useStyles(styles);

  const programType =
    programTypes && programTypes.find((pt) => pt.id === p.programTypeID);

  if (loading) {
    return (
      <div
        style={{
          padding: 12,
          border: "solid 1px rgb(238, 238, 239)",
          borderRadius: 4,
          cursor: "pointer",
          transition: "box-shadow 1s",
        }}
      >
        <Grid container spacing={1}>
          <Grid item>
            <Skeleton variant="rect" width={125} height={125} />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <Grid
              container
              direction="column"
              justify="space-between"
              style={{ height: "100%" }}
            >
              <Grid item>
                <Typography style={{ fontSize: 20, fontWeight: 700 }}>
                  <Skeleton variant="rect" width={"40%"} height={20} />
                </Typography>
                <br />
                <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                  <Skeleton variant="rect" width={"20%"} height={14} />
                </Typography>
              </Grid>
              <Grid item>
                <Skeleton variant="rect" width={"100%"} height={40} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  const ageFrom = ages.find((a) => a.id === p.ageFromID);
  const ageTo = ages.find((a) => a.id === p.ageToID);
  const gradeFrom = grades.find((a) => a.id === p.gradeFromID);
  const gradeTo = grades.find((a) => a.id === p.gradeToID);

  let icon = programType.icon;
  if (program.divisions && program.divisions[0] && program.divisions[0].icon) {
    icon = program.divisions[0].icon;
  }

  var divisions =
    program &&
    program.divisions &&
    program.divisions.filter(
      (e) => e.name.toLowerCase().indexOf("certificate") == -1
    );
  var certificates =
    program &&
    program.divisions &&
    program.divisions.filter(
      (e) => e.name.toLowerCase().indexOf("certificate") != -1
    );

  if (divisions == undefined) divisions = [];
  if (certificates == undefined) certificates = [];

  const getTags = (showDivision = false) => {
    var structured = {};
    const JSX = [];
    const _programType = programTypes.find(
      (pt) => pt.id === program.programTypeID
    );

    for (const key in divisions) {
      if (Object.hasOwnProperty.call(divisions, key)) {
        const d = divisions[key];
        const progType = programTypes.find((pt) => pt.id === d.programTypeID);

        if (progType.id) {
          if (structured[progType.name]) {
            structured[progType.name].push(d);
          } else {
            structured[progType.name] = [d];
          }
        }
      }
    }

    if (showDivision) {
      structured = { Divisions: structured[_programType.name] };
    } else {
      delete structured[_programType.name];
    }

    for (const name in structured) {
      if (Object.hasOwnProperty.call(structured, name)) {
        const element = structured[name];
        const _S = [
          <>
            <Divider />
            <Typography style={{ fontSize: 14, fontWeight: 700 }}>
              {name}
            </Typography>
          </>,
        ];
        const _L = [];
        for (const key in element) {
          if (Object.hasOwnProperty.call(element, key)) {
            const tag = element[key];
            _L.push(
              <li>
                <Typography variant="caption">{tag.name}</Typography>
              </li>
            );
          }
        }
        _S.push(<ul style={{ margin: 0 }}>{_L}</ul>);
        JSX.push(_S);
      }
    }

    return JSX;
  };

  console.log(gradeFrom, gradeTo);

  return (
    <div
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      style={{
        padding: 12,
        border: "solid 1px rgb(238, 238, 239)",
        borderRadius: 4,
        cursor: "pointer",
        boxShadow: hover
          ? "0px 0px 16px rgba(0,0,0,0.1)"
          : "0px 0px 0px rgba(155,155,155,0)",
        transition: "box-shadow 1s",
      }}
    >
      <Grid container spacing={2}>
        <Grid item>
          <div style={{ width: 125 }}>
            {p.picture ? (
              <img
                src={p.picture}
                alt="program-picture"
                style={{ width: 125, height: 125 }}
              />
            ) : (
              <img
                src={icon}
                alt="program-icon"
                style={{ width: 125, height: 125 }}
              />
            )}
            <br />
            {program.dateFrom || program.dateTo ? (
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <EventIcon style={{ fontSize: 14 }} />
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    style={{ fontWeight: 700 }}
                  >
                    Date
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              []
            )}
            {program.dateFrom ? (
              <Typography variant="caption" color="textSecondary">
                From {moment(program.dateFrom).format("ll")}
              </Typography>
            ) : (
              []
            )}
            <br />
            {program.dateTo ? (
              <Typography variant="caption" color="textSecondary">
                to {moment(program.dateTo).format("ll")}
              </Typography>
            ) : (
              []
            )}
            {program.city ? (
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <RoomIcon style={{ fontSize: 14 }} />
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    style={{ fontWeight: 700 }}
                  >
                    Location
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              []
            )}
            {program.city ? (
              <Typography variant="caption" color="textSecondary">
                {`${program.address}, ${program.city}, ${program.state} ${program.zipcode}`}
              </Typography>
            ) : (
              []
            )}
            {/* {program.contact ? (
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <PermContactCalendarIcon style={{ fontSize: 14 }} />
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    style={{ fontWeight: 700 }}
                  >
                    Contact
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="caption"
                    color="textPrimary"
                    style={{ fontWeight: 700 }}
                  >
                    {p.contact}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              []
            )} */}
          </div>
        </Grid>
        <Grid item style={{ width: "calc(100% - 148px)" }}>
          <Grid
            container
            direction="column"
            justify="space-between"
            style={{ height: "100%" }}
          >
            <Grid item>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Grid container justify="space-between">
                    <Grid item>
                      <Typography style={{ fontSize: 20, fontWeight: 700 }}>
                        {p.name}
                      </Typography>
                      <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                        <span
                          className={classes.tag}
                          style={{ background: programType.color || "#9e9e9e" }}
                        >
                          {programType.name}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item>
                      {ageFrom && ageTo ? (
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <PersonIcon style={{ fontSize: 14 }} />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="caption"
                              color="textPrimary"
                              style={{ fontWeight: 700 }}
                            >
                              Age from {ageFrom ? ageFrom.value : "-"} to{" "}
                              {ageTo ? ageTo.value : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        []
                      )}
                      {gradeTo && gradeFrom ? (
                        <Grid container alignItems="center" spacing={1}>
                          <Grid item>
                            <SchoolIcon style={{ fontSize: 14 }} />
                          </Grid>
                          <Grid item>
                            <Typography
                              variant="caption"
                              color="textPrimary"
                              style={{ fontWeight: 700 }}
                            >
                              Grade from {gradeFrom ? gradeFrom.name : "-"} to{" "}
                              {gradeTo ? gradeTo.name : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : (
                        []
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {program.details}
                </Grid>
                <Grid item xs={12}>
                  {getTags(false)}
                </Grid>
                {program && program.instruments.length ? (
                  <Grid item xs={12}>
                    <Divider />
                    <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                      Instruments
                    </Typography>
                    <Grid container spacing={1}>
                      {program.instruments.map((d) => (
                        <Grid item>
                          <Chip label={d.name} size="small" />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ) : (
                  []
                )}
                {program && program.levels.length ? (
                  <Grid item xs={12}>
                    <Divider />
                    <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                      Levels
                    </Typography>
                    <Grid container spacing={1}>
                      {program.levels.map((d) => (
                        <Grid item>
                          <Chip label={d.name} size="small" />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ) : (
                  []
                )}
                {program && program.musicalFamilies.length ? (
                  <Grid item xs={12}>
                    <Divider />
                    <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                      Musical Families
                    </Typography>
                    <Grid container spacing={1}>
                      {program.musicalFamilies.map((d) => (
                        <Grid item>
                          <Chip label={d.name} size="small" />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                ) : (
                  []
                )}
                {program.membershipRequired || program.nominationRequired ? (
                  <Grid item xs={12}>
                    <Divider />
                    <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                      Requirements
                    </Typography>
                    <Grid container spacing={1}>
                      {program.membershipRequired ? (
                        <Grid item>
                          <Chip
                            avatar={
                              <CardMembershipIcon style={{ color: "white" }} />
                            }
                            label={"Membership Required"}
                            style={{ background: "black", color: "white" }}
                            size="small"
                          />
                        </Grid>
                      ) : (
                        []
                      )}
                      {program.nominationRequired ? (
                        <Grid item>
                          <Chip
                            avatar={
                              <AssistantPhotoIcon style={{ color: "white" }} />
                            }
                            label={"Nomination Required"}
                            style={{ background: "black", color: "white" }}
                            size="small"
                          />
                        </Grid>
                      ) : (
                        []
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
              {program.description ? (
                <Grid item xs={12}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: program.description,
                    }}
                  />
                </Grid>
              ) : (
                []
              )}
            </Grid>
            <Grid item>{getTags(true)}</Grid>
            <Grid item>
              {p.website ? (
                <div
                  item
                  xs={12}
                  style={{
                    border: "solid 1px rgb(238, 238, 239)",
                    background: "rgb(244, 244, 244)",
                    padding: 8,
                    marginTop: 4,
                  }}
                >
                  <Button
                    variant="outlined"
                    style={{ textTransform: "none", background: "white" }}
                    onClick={() =>
                      window.open(
                        p.website.indexOf("http") !== -1
                          ? p.website
                          : `https://${p.website}`
                      )
                    }
                  >
                    Visit Website
                  </Button>
                </div>
              ) : (
                []
              )}
            </Grid>
          </Grid>
        </Grid>
        {p.updatedAt && p.updatedAt !== "0001-01-01T00:00:00Z" ? (
          <Grid item xs={12} style={{ textAlign: "right" }}>
            <Typography variant="caption" color="textSecondary">
              Last checked: {moment(p.updatedAt).format("ll")}
            </Typography>
          </Grid>
        ) : (
          []
        )}
      </Grid>
    </div>
  );
}
