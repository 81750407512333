import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'cpfm';
const instruments = `/${service}/instruments`;

export function getInstruments() {
  const url = `${instruments}`;
  return {
    method: GET,
    url,
  };
}

export function getInstrumentById(id) {
  const url = `${instruments}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateInstrument(id, body) {
  const url = `${instruments}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteInstrument(id) {
  const url = `${instruments}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createInstrument(body) {
  const url = `${instruments}`;
  return {
    method: POST,
    url,
    body,
  };
}
