import { networkAction } from 'helpers/network/networkAction';

import {
  GET_INSTRUMENTS,
  GET_INSTRUMENT_BY_ID,
  DELETE_INSTRUMENT,
  UPDATE_INSTRUMENT,
  CREATE_INSTRUMENT,
} from 'constants/instruments';

import * as Api from 'api';

export const getInstruments = () => async dispatch => networkAction(
  dispatch,
  GET_INSTRUMENTS,
  Api.getInstruments,
  [],
);

export const getInstrumentById = id => async dispatch => networkAction(
  dispatch,
  GET_INSTRUMENT_BY_ID,
  Api.getInstrumentById,
  [id],
);

export const deleteInstrument = id => async dispatch => networkAction(
  dispatch,
  DELETE_INSTRUMENT,
  Api.deleteInstrument,
  [id],
);

export const createInstrument = body => async dispatch => networkAction(
  dispatch,
  CREATE_INSTRUMENT,
  Api.createInstrument,
  [body],
);

export const updateInstrument = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_INSTRUMENT,
  Api.updateInstrument,
  [id, body],
);
