// material-ui
import { withStyles } from "@material-ui/core/styles";
// actions
import * as Actions from "actions";
// components
import App from "components/App";
import Loading from "components/Loading";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({
  programTypes: state.programTypes,
  levels: state.levels,
  ages: state.ages,
  grades: state.grades,
  divisions: state.divisions,
});

const mapDispatchToProps = (dispatch) => ({
  getPrograms: (...args) => dispatch(Actions.getPrograms(...args)),
  getAcademyByID: (...args) => dispatch(Actions.getAcademyByID(...args)),
  getAcademies: (...args) => dispatch(Actions.getAcademies(...args)),
  getAcademiesLight: (...args) => dispatch(Actions.getAcademiesLight(...args)),
  getProgramTypes: (...args) => dispatch(Actions.getProgramTypes(...args)),
  getAcademyPrograms: (...args) =>
    dispatch(Actions.getAcademyPrograms(...args)),
  getProgramById: (...args) => dispatch(Actions.getProgramById(...args)),
  getLevels: (...args) => dispatch(Actions.getLevels(...args)),
  getAges: (...args) => dispatch(Actions.getAges(...args)),
  getGrades: (...args) => dispatch(Actions.getGrades(...args)),
  getDivisions: (...args) => dispatch(Actions.getDivisions(...args)),
});

class Root extends Component {
  static propTypes = {
    classes: PropTypes.object,
    getAcademies: PropTypes.func,
    getAcademyByID: PropTypes.func,
    getAcademiesLight: PropTypes.func,
    getPrograms: PropTypes.func,
    getAcademyPrograms: PropTypes.func,
    getLevels: PropTypes.func,
    getAges: PropTypes.func,
    getGrades: PropTypes.func,
    getProgramById: PropTypes.func,
    academies: PropTypes.array,
    programTypes: PropTypes.array,
    programs: PropTypes.array,
    levels: PropTypes.array,
    ages: PropTypes.array,
    divisions: PropTypes.array,
    grades: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: true,
    };
    this.refresh();
    window.addEventListener("resize", this.refreshInnerHeight.bind(this));
  }

  componentDidMount() {
    this.refreshInnerHeight();
  }

  refreshInnerHeight() {
    // We execute the same script as before
    console.log("refreshInnerHeight");
    const vh = window.innerHeight * 0.01;
    this.setState({ vh });
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  async refresh() {
    const {
      getAcademiesLight,
      getProgramTypes,
      getAges,
      getGrades,
      getLevels,
      getDivisions,
    } = this.props;
    const resps = await Promise.all([
      getAcademiesLight(),
      getAges(),
      getGrades(),
      getLevels(),
      getProgramTypes(),
      getDivisions(),
    ]);

    const academies = resps[0].payload;
    const programTypes = resps[4].payload;

    const isProgramTypeVisible = {};

    for (const key in programTypes) {
      if (Object.hasOwnProperty.call(programTypes, key)) {
        const programType = programTypes[key];
        isProgramTypeVisible[programType.id] = programType.visible;
      }
    }

    // for (const key in programs) {
    //   if (programs.hasOwnProperty(key)) {
    //     const program = programs[key];
    //     if (isProgramTypeVisible[program.programTypeID]) {
    //       const academy = academies.find((a) => a.id === program.academyID);
    //       if (academy) {
    //         academy.hidden = false;
    //         if (academy.allPrograms) {
    //           academy.allPrograms.push(program);
    //         } else {
    //           academy.allPrograms = [program];
    //         }
    //         if (academy.programsByProgramTypes === undefined) {
    //           academy.programsByProgramTypes = {
    //             [program.programTypeID]: [program],
    //           };
    //         } else if (academy.programsByProgramTypes[program.programTypeID]) {
    //           academy.programsByProgramTypes[program.programTypeID].push(
    //             program
    //           );
    //         } else {
    //           academy.programsByProgramTypes[program.programTypeID] = [program];
    //         }
    //       }
    //     }
    //   }
    // }

    this.setState({
      loading: false,
      academies,
      isProgramTypeVisible,
      filterKey: new Date().getTime(),
      filters: {
        programTypes: [],
        divisions: [],
      },
    });
  }

  onFilterChange(newFilters) {
    const { academies } = this.state;
    this.setState({
      filters: newFilters,
    });

    for (const key in academies) {
      if (academies.hasOwnProperty(key)) {
        const academy = academies[key];
        academy.hidden = false;

        // Program Types
        if (newFilters.programTypes.length) {
          for (const key in newFilters.programTypes) {
            if (newFilters.programTypes.hasOwnProperty(key)) {
              const programType = newFilters.programTypes[key];
              if (
                academy.programsByProgramTypes &&
                academy.programsByProgramTypes[programType.id] === undefined
              ) {
                academy.hidden = true;
              }
              if (academy.programsByProgramTypes === undefined) {
                academy.hidden = true;
              }
            }
          }
        }

        // Divisions
        if (newFilters.divisions.length) {
          for (const key in newFilters.divisions) {
            if (newFilters.divisions.hasOwnProperty(key)) {
              const division = newFilters.divisions[key];
              console.log(division);
              debugger;
            }
          }
        }
      }
    }

    this.setState({ academies, filterKey: new Date().getTime() });
  }

  render() {
    const { loading, academies, filters, filterKey, isProgramTypeVisible } =
      this.state;
    const {
      getAcademyPrograms,
      programTypes,
      getProgramById,
      ages,
      grades,
      levels,
      divisions,
      getAcademyByID,
    } = this.props;

    if (loading) {
      return <Loading />;
    }

    return (
      <App
        academies={academies}
        programTypes={programTypes}
        getAcademyPrograms={getAcademyPrograms}
        getProgramById={getProgramById}
        ages={ages}
        onFilterChange={this.onFilterChange.bind(this)}
        filters={filters}
        isProgramTypeVisible={isProgramTypeVisible}
        grades={grades}
        filterKey={filterKey}
        getAcademyByID={getAcademyByID}
        divisions={divisions}
        levels={levels}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Root));
