import { networkAction } from 'helpers/network/networkAction';

import {
  GET_GRADES,
  GET_GRADE_BY_ID,
  DELETE_GRADE,
  UPDATE_GRADE,
  CREATE_GRADE,
} from 'constants/grades';

import * as Api from 'api';

export const getGrades = () => async dispatch => networkAction(
  dispatch,
  GET_GRADES,
  Api.getGrades,
  [],
);

export const getGradeById = id => async dispatch => networkAction(
  dispatch,
  GET_GRADE_BY_ID,
  Api.getGradeById,
  [id],
);

export const deleteGrade = id => async dispatch => networkAction(
  dispatch,
  DELETE_GRADE,
  Api.deleteGrade,
  [id],
);

export const createGrade = body => async dispatch => networkAction(
  dispatch,
  CREATE_GRADE,
  Api.createGrade,
  [body],
);

export const updateGrade = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_GRADE,
  Api.updateGrade,
  [id, body],
);
