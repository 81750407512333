import { networkAction } from 'helpers/network/networkAction';

import {
  GET_PROGRAM_DIVISIONS,
  GET_PROGRAM_DIVISION_BY_ID,
  DELETE_PROGRAM_DIVISION,
  UPDATE_PROGRAM_DIVISION,
  CREATE_PROGRAM_DIVISION,
} from 'constants/programDivisions';

import * as Api from 'api';

export const getProgramDivisions = () => async dispatch => networkAction(
  dispatch,
  GET_PROGRAM_DIVISIONS,
  Api.getProgramDivisions,
  [],
);

export const getProgramDivisionById = id => async dispatch => networkAction(
  dispatch,
  GET_PROGRAM_DIVISION_BY_ID,
  Api.getProgramDivisionById,
  [id],
);

export const deleteProgramDivision = id => async dispatch => networkAction(
  dispatch,
  DELETE_PROGRAM_DIVISION,
  Api.deleteProgramDivision,
  [id],
);

export const createProgramDivision = body => async dispatch => networkAction(
  dispatch,
  CREATE_PROGRAM_DIVISION,
  Api.createProgramDivision,
  [body],
);

export const updateProgramDivision = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_PROGRAM_DIVISION,
  Api.updateProgramDivision,
  [id, body],
);
