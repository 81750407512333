export default (theme) => ({
  root: {
    height: "calc(var(--vh, 1vh) * 100)",
    width: "100vw",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
  },
  flex: {
    display: "flex",
  },
  breakLine: {
    flexBasis: "100%",
    height: 0,
  },
  borderBottom: {
    borderBottom: "solid 1px #EEEEEF",
  },
  borderRight: {
    borderRight: "solid 1px #EEEEEF",
  },
  labelContainer: {
    padding: 0,
  },
  tabsRoot: {
    color: "white",
    padding: 2,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    marginRight: 6,
    textTransform: "none",
    minWidth: "auto",
    minHeight: "auto",
    fontSize: 12,
  },
});
