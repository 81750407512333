import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'cpfm';
const programDivisions = `/${service}/programDivisions`;

export function getProgramDivisions() {
  const url = `${programDivisions}`;
  return {
    method: GET,
    url,
  };
}

export function getProgramDivisionById(id) {
  const url = `${programDivisions}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateProgramDivision(id, body) {
  const url = `${programDivisions}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteProgramDivision(id) {
  const url = `${programDivisions}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createProgramDivision(body) {
  const url = `${programDivisions}`;
  return {
    method: POST,
    url,
    body,
  };
}
