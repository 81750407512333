import { networkAction } from 'helpers/network/networkAction';

import {
  GET_LEVELS,
  GET_LEVEL_BY_ID,
  DELETE_LEVEL,
  UPDATE_LEVEL,
  CREATE_LEVEL,
} from 'constants/levels';

import * as Api from 'api';

export const getLevels = () => async dispatch => networkAction(
  dispatch,
  GET_LEVELS,
  Api.getLevels,
  [],
);

export const getLevelById = id => async dispatch => networkAction(
  dispatch,
  GET_LEVEL_BY_ID,
  Api.getLevelById,
  [id],
);

export const deleteLevel = id => async dispatch => networkAction(
  dispatch,
  DELETE_LEVEL,
  Api.deleteLevel,
  [id],
);

export const createLevel = body => async dispatch => networkAction(
  dispatch,
  CREATE_LEVEL,
  Api.createLevel,
  [body],
);

export const updateLevel = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_LEVEL,
  Api.updateLevel,
  [id, body],
);
