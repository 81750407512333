import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "cpfm";
const academies = `/${service}/academies`;

export function getAcademies() {
  const url = `${academies}`;
  return {
    method: GET,
    url,
  };
}

export function getAcademiesLight() {
  const url = `${academies}/light`;
  return {
    method: GET,
    url,
  };
}

export function academySearch(filters) {
  const url = `${academies}/search${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getAcademyByID(id) {
  const url = `${academies}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function getAcademyPrograms(id) {
  const url = `${academies}/${id}/programs`;
  return {
    method: GET,
    url,
  };
}

export function updateAcademy(id, body) {
  const url = `${academies}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteAcademy(id) {
  const url = `${academies}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createAcademy(body) {
  const url = `${academies}`;
  return {
    method: POST,
    url,
    body,
  };
}
