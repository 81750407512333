import user from './user';
import programs from './programs';
import academies from './academies';
import grades from './grades';
import levels from './levels';
import ages from './ages';
import programTypes from './programTypes';
import divisions from './divisions';
import { combineReducers } from 'redux'; //eslint-disable-line
export default combineReducers({
  user,
  programs,
  academies,
  programTypes,
  grades,
  levels,
  divisions,
  ages,
});
