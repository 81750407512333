import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'cpfm';
const divisions = `/${service}/divisions`;

export function getDivisions() {
  const url = `${divisions}`;
  return {
    method: GET,
    url,
  };
}

export function getDivisionById(id) {
  const url = `${divisions}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateDivision(id, body) {
  const url = `${divisions}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteDivision(id) {
  const url = `${divisions}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createDivision(body) {
  const url = `${divisions}`;
  return {
    method: POST,
    url,
    body,
  };
}
