import {
  GET_AGES,
} from 'constants/ages';

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_AGES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
