import {
  GET_GRADES,
} from 'constants/grades';

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_GRADES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
