import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'cpfm';
const grades = `/${service}/grades`;

export function getGrades() {
  const url = `${grades}`;
  return {
    method: GET,
    url,
  };
}

export function getGradeById(id) {
  const url = `${grades}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateGrade(id, body) {
  const url = `${grades}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteGrade(id) {
  const url = `${grades}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createGrade(body) {
  const url = `${grades}`;
  return {
    method: POST,
    url,
    body,
  };
}
