import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'cpfm';
const musicalFamilies = `/${service}/musicalFamilies`;

export function getMusicalFamilies() {
  const url = `${musicalFamilies}`;
  return {
    method: GET,
    url,
  };
}

export function getMusicalFamilyById(id) {
  const url = `${musicalFamilies}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateMusicalFamily(id, body) {
  const url = `${musicalFamilies}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteMusicalFamily(id) {
  const url = `${musicalFamilies}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createMusicalFamily(body) {
  const url = `${musicalFamilies}`;
  return {
    method: POST,
    url,
    body,
  };
}
