import {
  GET,
  PUT,
  DELETE,
  POST,
} from 'constants/methods';

const service = 'cpfm';
const levels = `/${service}/levels`;

export function getLevels() {
  const url = `${levels}`;
  return {
    method: GET,
    url,
  };
}

export function getLevelById(id) {
  const url = `${levels}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updateLevel(id, body) {
  const url = `${levels}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deleteLevel(id) {
  const url = `${levels}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createLevel(body) {
  const url = `${levels}`;
  return {
    method: POST,
    url,
    body,
  };
}
