import * as Api from "api";
import {
  ACADEMY_SEARCH,
  CREATE_ACADEMY,
  DELETE_ACADEMY,
  GET_ACADEMIES,
  GET_ACADEMIES_LIGHT,
  GET_ACADEMY_BY_ID,
  GET_ACADEMY_PROGRAMS,
  UPDATE_ACADEMY,
} from "constants/academies";
import { networkAction } from "helpers/network/networkAction";

export const getAcademies = () => async (dispatch) =>
  networkAction(dispatch, GET_ACADEMIES, Api.getAcademies, []);

export const getAcademiesLight = () => async (dispatch) =>
  networkAction(dispatch, GET_ACADEMIES_LIGHT, Api.getAcademiesLight, []);

export const academySearch = (filters) => async (dispatch) =>
  networkAction(dispatch, ACADEMY_SEARCH, Api.academySearch, [filters]);

export const getAcademyByID = (id) => async (dispatch) =>
  networkAction(dispatch, GET_ACADEMY_BY_ID, Api.getAcademyByID, [id]);

export const getAcademyPrograms = (id) => async (dispatch) =>
  networkAction(dispatch, GET_ACADEMY_PROGRAMS, Api.getAcademyPrograms, [id]);

export const deleteAcademy = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_ACADEMY, Api.deleteAcademy, [id]);

export const createAcademy = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_ACADEMY, Api.createAcademy, [body]);

export const updateAcademy = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_ACADEMY, Api.updateAcademy, [id, body]);
