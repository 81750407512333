import { networkAction } from 'helpers/network/networkAction';

import {
  GET_PROGRAM_MUSICAL_FAMILIES,
  GET_PROGRAM_MUSICAL_FAMILY_BY_ID,
  DELETE_PROGRAM_MUSICAL_FAMILY,
  UPDATE_PROGRAM_MUSICAL_FAMILY,
  CREATE_PROGRAM_MUSICAL_FAMILY,
} from 'constants/programMusicalFamilies';

import * as Api from 'api';

export const getProgramMusicalFamilies = () => async dispatch => networkAction(
  dispatch,
  GET_PROGRAM_MUSICAL_FAMILIES,
  Api.getProgramMusicalFamilies,
  [],
);

export const getProgramMusicalFamilyById = id => async dispatch => networkAction(
  dispatch,
  GET_PROGRAM_MUSICAL_FAMILY_BY_ID,
  Api.getProgramMusicalFamilyById,
  [id],
);

export const deleteProgramMusicalFamily = id => async dispatch => networkAction(
  dispatch,
  DELETE_PROGRAM_MUSICAL_FAMILY,
  Api.deleteProgramMusicalFamily,
  [id],
);

export const createProgramMusicalFamily = body => async dispatch => networkAction(
  dispatch,
  CREATE_PROGRAM_MUSICAL_FAMILY,
  Api.createProgramMusicalFamily,
  [body],
);

export const updateProgramMusicalFamily = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_PROGRAM_MUSICAL_FAMILY,
  Api.updateProgramMusicalFamily,
  [id, body],
);
